import { arrow, computePosition, offset, shift } from '@floating-ui/dom'

export const openDropdown = (DropdownToggler) => {
  const dropdownId = `#dropdown-${DropdownToggler.getAttribute('data-dropdown')}`
  const DropdownMenu = document.querySelector(dropdownId)
  const DropdownArrow = DropdownMenu.querySelector('.dropdown-arrow')
  const placement = DropdownMenu.getAttribute('data-placement') || 'bottom'

  if (!DropdownMenu.classList.contains('shown')) {
    computePosition(DropdownToggler, DropdownMenu, {
      placement: placement,
      middleware: [offset(10), shift({ padding: 10 }), arrow({ element: DropdownArrow, padding: 12 })],
    }).then(({ x, y, middlewareData: { arrow } }) => {
      Object.assign(DropdownMenu.style, {
        top: `${y}px`,
        left: `${x}px`,
      })

      const { x: arrowX } = arrow ?? {}
      Object.assign(DropdownArrow.style, {
        left: `${arrowX}px`,
        top: `-6px`,
      })

      setTimeout(() => DropdownMenu.classList.add('shown'), 1)
    })
  }
}

export const closeDropdowns = () => {
  const DropdownMenus = document.querySelectorAll('.dropdown')
  DropdownMenus.forEach((Menu) => Menu.classList.remove('shown'))
}
