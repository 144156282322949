/**
 * Accordion
 */
const Accordions = document.querySelectorAll('.accordion')
if (Accordions.length) {
  Accordions.forEach((Accordion) => {
    const AccordionItems = Accordion.querySelectorAll('.accordion-item')
    AccordionItems.forEach((AccordionItem) => {
      const AccordionToggler = AccordionItem.querySelector('.accordion-item-toggler')
      AccordionToggler.addEventListener('click', ({ target }) => {
        const AccordionItem = target.closest('.accordion-item')
        AccordionItems.forEach((item) => {
          item.classList.remove('shown-md')
          item.classList.toggle('shown', AccordionItem === item)
        })
      })
    })
  })
}
