// TODO: Rework to class for better usability?
// export const getCookie = (cname) => {
//   let name = cname + '=',
//     ca = document.cookie.split(';'),
//     i,
//     c,
//     ca_length = ca.length
//   for (i = 0; i < ca_length; i += 1) {
//     c = ca[i]
//     while (c.charAt(0) === ' ') {
//       c = c.substring(1)
//     }
//     if (c.indexOf(name) !== -1) {
//       return c.substring(name.length, c.length)
//     }
//   }
//   return ''
// }

export const setCookie = (name, value, expires) => {
  let d = new Date()
  d = new Date(d.getTime() + 1000 * expires)
  document.cookie = name + '=' + value + '; path=/; expires=' + d.toGMTString() + ';'
}
